<template>
  <div class="tooltip" @click.stop="">
    <div class="tip-bg" v-if="showTip" @click="HandleClose"></div>
    <Pic class="ml-4" src="user/help" width="16" height="16" @click="HandleTaggle" />
    <div class="tip-arrow" v-if="showTip"></div>
    <div class="tiptext" v-if="showTip">
      <p><slot /></p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheTooltip',
  components: { Pic },
  setup () {
    const showTip = shallowRef(false)
    const HandleTaggle = () => {
      showTip.value = !showTip.value
    }
    const HandleClose = () => {
      showTip.value = false
    }
    return {
      showTip,
      HandleTaggle,
      HandleClose,
    }
  },
})

</script>

<style scoped lang="scss">
.tooltip{
  position: relative;
  width: 16px;
  height: 16px;
  .tip-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }
  .tip-arrow{
    position: absolute;
    top: 16px;
    right: 1px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 6px solid rgba(24, 29, 36, 0.9);
  }
  .tiptext{
    position: absolute;
    background-color: rgba(24, 29, 36, 0.9);
    right: -3px;
    top: 22px;
    width: calc(100vw - 60px);
    border-radius: 3px;
    z-index: 1;
    p{
      text-align: left;
      padding: 12px 16px;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: #fff;
    }
  }
}
</style>
