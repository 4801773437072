<template>
  <PageWithHeader>
    <template #title>
      <t path="interest_2">Interest</t>
    </template>
    <template #body>
      <Scroller @bottomTouched="loadMore">
        <div class="px-16 fill df-col flex-1">
          <div class="f-md my-16 c-title" style="line-height: 20px">
            <t as="div" path="interest_3" multiline #="{td}" custom>
              <p v-for="(p, index) in td" :key="index">{{p}}</p>
            </t>
          </div>
          <div class="py-16 df-col flex-1 mb-32">
            <div class="total px-16 py-12 mx-16" style="background-color: var(--color-background)">
              <p class="t-item df-between mb-8">
                <t path="interest_4" class="c-title">Current Balance</t>
                <Money class="f-medium" :v="prop('currentBalance', data)"></Money>
              </p>
              <p class="t-item df-between mb-8">
                <t path="interest_5" class="c-title">Interest rate /YR</t>
                <ColorText
                  class="f-nm f-medium"
                  :value="prop('interestRate', data)"
                >{{ prop("interestRate", data) + "%" }}</ColorText>
              </p>
              <p class="t-item df-between mb-8">
                <t path="interest_6" class="c-title">Estimated interest /YP</t>
                <Money class="f-medium f-nm" #="{ text, origin }" :v="prop('interest', data)">
                  <ColorText :value="prop('interest', data)">{{ text }}</ColorText>
                </Money>
              </p>
              <p class="t-item df-between">
                <t path="interest_7" class="c-title">Interest credited</t>
                <Money class="f-medium f-nm" #="{ text, origin }" :v="prop('interestCredit', data)">
                  <ColorText :value="prop('interestCredit', data)">{{text}}</ColorText>
                </Money>
              </p>
            </div>
            <div class="i-table df-col my-16 flex-1 pb-32">
              <div class="i-t-head px-16">
                <p class="df-between df-middle td-level c-tip f-sm mb-8" style="height: 24px">
                  <t path="interest_8">Date</t>
                  <t path="interest_9">Balance</t>
                  <t path="interest_10">Interest</t>
                </p>
              </div>
              <Holder :content="loaded" :skeleton-repeat="1" holder-class="px-16">
                <List :list-data="loaded" #="{ item }" pull-refresh @refresh="refresh">
                  <p class="df-between df-middle td-level f-nm c-text mb-16 px-16">
                    <span>{{ item.issue }}</span>
                    <Money :v="item.balance" />
                    <span class="df-middle">
                      <Money class="f-medium f-nm" #="{ text, origin }" :v="item.interest">
                        <ColorText :value="item.interest">{{ text }}</ColorText>
                      </Money>
                      <Tooltip v-if="item.cause">
                        <p>{{ item.cause }}</p>
                      </Tooltip>
                    </span>
                  </p>
                </List>
              </Holder>
            </div>
          </div>
        </div>
      </Scroller>
    </template>
  </PageWithHeader>
</template>
<script lang="ts">
import { defineComponent, shallowRef } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Tooltip from '@/pages/interest/components/Tooltip.vue'
import ColorText from '@/components/ColorText.vue'
import { mul } from 'essential/tools/math'
import {
  readInterestInfo,
  readInterestList,
  InterestItem,
} from '@/pages/interest/interest.api'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import useLoadMore from 'common/hooks/useLoadMore'
import Scroller from 'common/providers/Scroller.vue'
import Money from '@/components.global/Money'

export default defineComponent({
  name: 'Interest',
  components: {
    PageWithHeader,
    Tooltip,
    ColorText,
    List,
    Holder,
    Scroller,
    Money,
  },
  setup () {
    const data = shallowRef()
    // 获取账户利息信息
    const getInterestPre = () => {
      readInterestInfo().then((res) => {
        res.interestRate = mul(res.interestRate, 100)
        data.value = res
      })
    }
    getInterestPre()

    // 获取利息历史列表
    const { loaded, refresh, loadMore } = useLoadMore<
      InterestItem,
      Array<InterestItem>
    >(readInterestList, 10, (resp) => resp)
    refresh()
    return {
      data,
      loaded,
      loadMore,
      refresh (done: () => void) {
        refresh().then(done)
      },
    }
  },
})
</script>
<style scoped lang="scss">
.t-item {
  font-size: 15px;
  font-weight: 400;
}
.td-level {
  span:nth-child(1) {
    flex: 1;
  }
  span:nth-child(2) {
    flex: 1;
    text-align: center;
  }
  span:nth-child(3) {
    flex: 1;
    text-align: right;
  }
}
</style>
